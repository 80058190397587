// index.js
import React from 'react';
import PropTypes from 'prop-types';
import {
  TooltipOneContainer,
  TooltipTwoContainer,
  TooltipThreeContainer,
  TooltipFourContainer,
  TooltipFiveContainer,
  TooltipLabel,
  TooltipValue,
  TooltipDate,
} from './index.sc';
import {
  blueColorGradients,
  coolGrayColorGradients,
  greenColorGradients,
  purpleColorGradients,
  redColorGradients,
} from '../../../../constants/graph-colors';

const SocialTooltip = ({ tooltipData, widget }) => {
  const renderTooltip = () => {
    switch (widget.customClassName) {
      case 'advanced_result_over_time':
        return (
          <TooltipOneContainer>
            <TooltipDate>{tooltipData?.data?.label}</TooltipDate>
            <TooltipValue>
              {Number(tooltipData?.data.doc_count).toLocaleString('en-US')}
            </TooltipValue>
          </TooltipOneContainer>
        );

      case 'people_popular_topic_themes':
      case 'advanced_top_themes':
      case 'advanced_top_themes_pre':
      case 'advanced_top_themes_during':
      case 'advanced_top_themes_post':
        return (
          <TooltipTwoContainer>
            <TooltipLabel color={tooltipData?.data?.labelColor}>
              {tooltipData?.data?.label}
            </TooltipLabel>
            <TooltipValue>
              {Number(tooltipData?.data?.thresholdValue).toLocaleString(
                'en-US'
              )}
            </TooltipValue>
          </TooltipTwoContainer>
        );

      case 'advanced_campaign_sentiment_over_time':
        return (
          <TooltipThreeContainer>
            <div>
              <TooltipLabel color={greenColorGradients.green60}>
                Positive
              </TooltipLabel>
              <TooltipValue>{tooltipData?.data?.POS}</TooltipValue>
            </div>
            <div>
              <TooltipLabel color={redColorGradients.red60}>
                Negative
              </TooltipLabel>
              <TooltipValue>{tooltipData?.data?.NEG}</TooltipValue>
            </div>
            <div>
              <TooltipLabel color={coolGrayColorGradients.coolGray60}>
                Neutral
              </TooltipLabel>
              <TooltipValue>{tooltipData?.data?.NEU}</TooltipValue>
            </div>
          </TooltipThreeContainer>
        );

      case 'advanced_campaign_engagement':
        return (
          <TooltipFourContainer>
            <div>
              <TooltipLabel color={purpleColorGradients.purple70}>
                {tooltipData?.data?.label}
              </TooltipLabel>
              <TooltipLabel>{tooltipData?.data?.labelText}</TooltipLabel>
            </div>

            <TooltipValue>{tooltipData?.data.accValue}</TooltipValue>
          </TooltipFourContainer>
        );

      case 'advanced_campaign_content_type':
        return (
          <TooltipFiveContainer>
            <TooltipLabel color={blueColorGradients.blue60}>
              {tooltipData?.data?.label}
            </TooltipLabel>
            <TooltipValue>{tooltipData?.data.accValue}</TooltipValue>
          </TooltipFiveContainer>
        );

      default:
        return null;
    }
  };

  return <div>{renderTooltip()}</div>;
};

SocialTooltip.propTypes = {
  tooltipData: PropTypes.object,
  widget: PropTypes.object,
};

export default SocialTooltip;

import styled from 'styled-components';

export const ResultsInFigureWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  padding: 0.75rem;
  border: 1px solid ${({ theme }) => theme?.background};
  border-radius: 0.75rem;
  background: linear-gradient(180deg, #e8dbff 0%, #dde1e6 100%);
  width: 100%;
  /* border: none; */
  min-height: 105px;
  margin-bottom: 0.5rem;
`;
export const ResultInFigContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 0.625rem;
  background: ${({ theme }) => theme?.background};
  width: 100%;
  min-height: 81px;
`;
export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
`;
// export const ResultValueWpr = styled.div``;
export const ResultInNumberWrp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
export const ResultInNumber = styled.div`
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: -0.04em;
  text-align: left;
  white-space: nowrap;
  color: ${({ theme }) => theme?.text};
`;

export const ResultIndicatorWpr = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 0.125rem;
  background-color: ${(props) =>
    props.isIncreased ? 'rgba(242, 255, 243, 1)' : 'rgba(255, 240, 241, 1)'};
  padding: 0.125rem 0.375rem 0.125rem 0.125rem;
  border-radius: 0.25rem;
  align-items: center;
`;

export const IndicatorIconWrp = styled.div``;
export const IndicatorPercentageWrp = styled.div`
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${(props) =>
    props.isIncreased ? 'rgba(36, 182, 41, 1)' : 'rgba(216, 54, 64, 1)'};
`;
export const ResultTitle = styled.div`
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 15.73px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.inActiverticalDots};
  display: flex;
  align-items: flex-end;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${({ theme }) => theme.background};
  padding: 10px;
`;
export const TooltipTitle = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 0.5rem;
  color: #585858;
  text-transform: capitalize;
`;
export const TooltipValue = styled.div`
  font-weight: 700;
  font-size: 0.95rem;
  line-height: 0.75rem;
  color: #000000;
`;

export const ChangeWrp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  max-width: 160px;
`;

export const ChangeTxt = styled.div`
  color: ${(theme) => theme.graphAxisColor};
  font-weight: 700;
  font-size: 0.95rem;
  line-height: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1rem;
  width: 12rem;
`;

export const TextWrp = styled.div`
  color: ${({ theme }) => theme.grey100};
  font-weight: 600;
  font-size: 0.813rem;
`;

export const Change = styled.div`
  display: flex;
  align-items: center;
  /* gap: 0.625rem; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 0.813rem;
  color: ${(props) =>
    props.isIncreased ? 'rgba(36, 182, 41, 1)' : 'rgba(216, 54, 64, 1)'};
  font-size: 0.813rem;
  line-height: 1rem;
  letter-spacing: -0.016rem;
  margin: 0;
  background-color: ${(props) =>
    props.isIncreased ? 'rgba(242, 255, 243, 1)' : 'rgba(255, 240, 241, 1)'};
  padding: 4px 6px;
  border-radius: 4px;
`;

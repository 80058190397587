import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '../../../utils';
import ArrowIncrease from '../../../assets/icons/ArrowIncrease';
import ArrowDecrease from '../../../assets/icons/ArrowDecrease';
import {
  Change,
  ChangeWrp,
  PercentWrp,
  Tile,
  TileData,
  TileDataWrp,
  TileTitle,
  TooltipWrapper,
} from './index.sc';
import infoIcon from '../../../assets/img/infoIcon.svg';
import ReachTooltip from '../../tooltip';
import PortalTooltip from '../../portal-tooltip';
import {
  ChartLabelExtraSmallWrp,
  ChartLabelLargeWrp,
  ChartLabelSmallWrp,
} from '../../../constants/graph-colors/index.sc';
import { coolGrayColorGradients } from '../../../constants/graph-colors';
import Minus from '../../../assets/icons/Minus';

const SummaryTooltip = ({
  tooltipData = {
    label: '',
    value: '',
    isIncreased: null,
    change: '',
    previousCount: '',
  },
}) => {
  const {
    label = '',
    value = '',
    isIncreased = null,
    change = '',
    previousCount,
  } = tooltipData;
  return (
    <TooltipWrapper>
      {/* <TooltipTitle>{label}</TooltipTitle>
      <TooltipValue>
        {label === 'Total AVE' && '~$'}
        {value?.toLocaleString('en-US')}
      </TooltipValue> */}
      <ChangeWrp>
        <Change isIncreased={isIncreased} isToolTip={true}>
          {isIncreased ? <ArrowIncrease /> : <ArrowDecrease />}
          {parseInt(change || 0)?.toLocaleString('en-US')}%{' '}
        </Change>{' '}
        <ChartLabelExtraSmallWrp
          style={{ color: coolGrayColorGradients.coolGray70 }}
        >
          {isIncreased
            ? 'Increase in comparison to previous period'
            : 'Decrease in comparison to previous period'}
        </ChartLabelExtraSmallWrp>
        <div style={{ marginTop: '6px' }}>
          <ChartLabelSmallWrp
            style={{ color: coolGrayColorGradients.coolGray70 }}
          >
            {label === 'Total Results' ? 'Previous Count' : 'Previous Value'}
          </ChartLabelSmallWrp>
          <ChartLabelLargeWrp style={{ color: 'black', marginTop: '2px' }}>
            {label === 'Total AVE' && '~$'}
            {tooltipData?.previousCount?.toLocaleString('en-US')}
          </ChartLabelLargeWrp>
        </div>
      </ChangeWrp>
    </TooltipWrapper>
  );
};

const OverviewTooltip = ({ tooltipData }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
      <ChartLabelSmallWrp style={{ color: coolGrayColorGradients.coolGray70 }}>
        {tooltipData?.label === 'Total Results'
          ? 'Total Articles'
          : tooltipData?.label}
      </ChartLabelSmallWrp>
      <ChartLabelLargeWrp style={{ color: 'black' }}>
        {tooltipData?.label === 'Total AVE' && '~$'}
        {tooltipData?.value?.toLocaleString('en-US')}
      </ChartLabelLargeWrp>
    </div>
  );
};

const UberStatsVolumeTile = ({
  title,
  data,
  isIncreased,
  change,
  handleClick = () => {},
  previousCount = '',
}) => {
  const { value, midSuffix } = formatNumber(data, true, true);

  const [showTooltip, setShowTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [showOverviewTooltip, setShowOverviewTooltip] = useState(false);
  const [overviewToolTipPos, setOverviewToolTipPos] = useState({
    left: 0,
    top: 0,
  });
  const [overviewTooltipData, setOverviewTooltipData] = useState();

  const handleMouseEnter = (event) => {
    setShowTooltip(true);
    setToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
    setTooltipData({
      label: title,
      value: data,
      isIncreased,
      change,
      previousCount,
    });
  };

  const handleMouseMove = (event) => {
    setToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
  };

  const handleMouseLeave = (e) => {
    setToolTipPos({
      left: 0,
      top: 0,
    });
    setTooltipData();
    setShowTooltip(false);
  };

  const handleOverviewMouseEnter = (event) => {
    setShowOverviewTooltip(true);
    setOverviewToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
    setOverviewTooltipData({
      label: title,
      value: data,
      isIncreased,
      change,
      previousCount,
    });
  };

  const handleOverviewMouseMove = (event) => {
    setOverviewToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
  };

  const handleOverviewMouseLeave = (e) => {
    setOverviewToolTipPos({
      left: 0,
      top: 0,
    });
    setOverviewTooltipData();
    setShowOverviewTooltip(false);
  };

  return (
    <Tile onClick={handleClick}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <TileDataWrp>
            <ChangeWrp>
              <TileData
                onMouseEnter={handleOverviewMouseEnter}
                onMouseMove={handleOverviewMouseMove}
                onMouseLeave={handleOverviewMouseLeave}
              >
                {title === 'Total AVE' && '~$'}
                {value}
                <PercentWrp> {midSuffix}</PercentWrp>
              </TileData>
            </ChangeWrp>
          </TileDataWrp>
          <TileTitle
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: title === 'Total AVE' ? 'flex-start' : 'center',
            }}
          >
            <div
              style={{ marginTop: '5px' }}
              onMouseEnter={handleOverviewMouseEnter}
              onMouseMove={handleOverviewMouseMove}
              onMouseLeave={handleOverviewMouseLeave}
            >
              {title === 'Total Results' ? 'Total Articles' : title}
            </div>
            {(title === 'Total Reach' || title === 'Traditional Reach') && (
              <ReachTooltip
                content="Reach estimates the potential viewership of any particular result based on the number of visitors to the specific source on both desktop and mobile"
                direction="right"
              >
                <img
                  src={infoIcon}
                  alt="info"
                  style={{ marginLeft: '4px', marginTop: '7px' }}
                />
              </ReachTooltip>
            )}
          </TileTitle>
        </div>
        {!(parseInt(change) === 0) && (
          <Change
            isIncreased={isIncreased}
            isToolTip={false}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            {isIncreased ? <ArrowIncrease /> : <ArrowDecrease />}
            <span>{(parseInt(change) || 0)?.toLocaleString('en-US')}%</span>
          </Change>
        )}
        {parseInt(change) === 0 && (
          <Change
            isIncreased={isIncreased}
            isToolTip={false}
            style={{
              backgroundColor: coolGrayColorGradients.coolGray10,
              color: coolGrayColorGradients.coolGray60,
            }}
          >
            {' '}
            <Minus /> <span style={{ paddingLeft: '0px' }}>0%</span>{' '}
          </Change>
        )}
      </div>

      {showTooltip && (
        <PortalTooltip
          isOpen={true}
          pos={toolTipPos}
          align={toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'}
          vAlign={'top'}
        >
          <SummaryTooltip tooltipData={tooltipData} />
        </PortalTooltip>
      )}
      {showOverviewTooltip && (
        <PortalTooltip
          isOpen={true}
          pos={overviewToolTipPos}
          align={
            overviewToolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'
          }
          vAlign={'top'}
          boxShadow={'0px 8px 20px 0px rgba(0, 0, 0, 0.12)'}
        >
          <TooltipWrapper>
            <OverviewTooltip tooltipData={overviewTooltipData} />
          </TooltipWrapper>
        </PortalTooltip>
      )}
    </Tile>
  );
};

SummaryTooltip.propTypes = {
  tooltipData: PropTypes.object,
};

OverviewTooltip.propTypes = {
  tooltipData: PropTypes.object,
};

UberStatsVolumeTile.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isIncreased: PropTypes.bool,
  change: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClick: PropTypes.bool,
  previousCount: PropTypes.string,
};

export default UberStatsVolumeTile;

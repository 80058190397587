import React from 'react';

const Minus = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_16615_3"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect
          x="24"
          y="24"
          width="24"
          height="24"
          transform="rotate(-180 24 24)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_16615_3)">
        <path
          d="M16.6004 12.9995L7.40039 12.9995L7.40039 10.9995L16.6004 10.9995L16.6004 12.9995Z"
          fill="#C1C7CD"
        />
      </g>
    </svg>
  );
};

export default Minus;

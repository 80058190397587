import { DateTime } from 'luxon';

/**
 * Filters out properties from an object based on a set of criteria.
 *
 * @param {Object} inputData - The input data object to be filtered
 * @returns {Object} The filtered data object
 * Example: it will filter out properties from the input data object where it is false.
 */
export function refineSelectedGraphs(inputData) {
  // Filter out properties with true values and non-empty objects
  return Object.fromEntries(
    Object.entries(inputData).filter(([key, value]) => {
      return (
        Object.values(value).some((v) => v === true) &&
        Object.keys(value).length > 0
      );
    })
  );
}

/**
 * This function takes an array of charts and returns an object with the categories as keys and the chart IDs as values.
 * The value for each key-value pair is always set to true.
 *
 * @param {Array} chartsArray - An array of charts
 * @returns {Object} An object with the categories as keys and the chart IDs as values
 * example: {
    "industry": {
        "industry_volume_analysis": true,
        "industry_sentiment_analysis": true,
        // ... other industry charts
    }}
 */
export function convertToSelectedGraphsFormat(chartsArray) {
  const result = {};

  // Helper function to determine the category
  function getCategory(chart) {
    if (chart.chartId.startsWith('industry')) {
      return 'industry';
    } else if (chart.chartId.startsWith('people')) {
      return 'people';
    } else if (
      socialCampaignCharts.some((ch) => ch.chartId === chart.chartId)
    ) {
      return 'socialCampaign';
    } else if (
      campaignMonitorCharts.some((ch) => ch.chartId === chart.chartId)
    ) {
      return 'campaign';
    } else {
      return 'brand';
    }
  }

  // Helper function to add entries to the result object
  function addEntry(category, chartId, value) {
    if (!result[category]) {
      result[category] = {};
    }
    result[category][chartId] = value;
  }

  // Process charts
  chartsArray.forEach((chart) => {
    const category = getCategory(chart);
    addEntry(category, chart.chartId, true);
  });

  return result;
}

export function countTruthyValues(inputObject) {
  const result = {};

  for (const category in inputObject) {
    // Check if the property is present and is an object (to avoid issues with prototype properties)
    if (inputObject[category] && typeof inputObject[category] === 'object') {
      const categoryData = inputObject[category];

      // Count truthy values
      let totalCount = 0;

      // Separate count for brandOriginal and brandComp
      let brandOriginalCount = 0;
      let brandCompCount = 0;

      for (const key in categoryData) {
        // Using truthy check to include values like 0, false, empty strings
        if (categoryData[key]) {
          totalCount++;

          // Special handling for "brand" property
          if (category === 'brand') {
            const chartId = key; // Remove 'brand_' prefix

            if (brandCharts.some((chart) => chart.chartId === chartId)) {
              brandOriginalCount++;
            } else if (
              competitionCharts.some((chart) => chart.chartId === chartId)
            ) {
              brandCompCount++;
            }
          }
        }
      }

      // Include the category even if there are no truthy values
      result[category] = totalCount;

      // Add counts for brandOriginal and brandComp
      if (category === 'brand') {
        result.brandNotcomp = brandOriginalCount;
        result.brandComp = brandCompCount;
      }
    }
  }

  return result;
}

export const brandCharts = [
  {
    chartId: 'volume_analysis',
    chartName: 'Key Metrics Overview',
  },
  {
    chartId: 'sentiment_analysis',
    chartName: 'Brand Sentiment',
  },
  {
    chartId: 'sentiment_over_time',
    chartName: 'Sentiment Over Time',
    traditionalChartName: 'Sentiment Over Time - Traditional',
  },
  {
    chartId: 'sentiment_over_time_social',
    chartName: 'Sentiment Over Time - Social',
  },
  {
    chartId: 'coverage_over_time',
    chartName: 'Results Over Time ',
    traditionalChartName: 'Results Over Time - Traditional',
  },
  {
    chartId: 'coverage_over_time_social',
    chartName: 'Results Over Time - Social',
  },
  {
    chartId: 'reach_over_time',
    chartName: 'Reach Over Time',
    traditionalChartName: 'Reach Over Time - Traditional',
  },
  {
    chartId: 'reach_over_time_social',
    chartName: 'Reach Over Time - Social',
  },
  {
    chartId: 'media_type',
    chartName: 'Data Sources',
  },
  {
    chartId: 'brand_companies_mentioned',
    chartName: 'Brand Mentions',
  },
];

export const competitionCharts = [
  { chartId: 'sov', chartName: 'SOV' },
  { chartId: 'article_sentiment', chartName: 'Brand Sentiment' },
  {
    chartId: 'competitive_coverage_over_time',
    chartName: 'Results Over Time ',
    traditionalChartName: 'Results Over Time - Traditional',
  },
  {
    chartId: 'competitive_coverage_over_time_social',
    chartName: 'Results Over Time - Social',
  },
  {
    chartId: 'competitive_reach_over_time',
    chartName: 'Reach Over Time',
    traditionalChartName: 'Reach Over Time - Traditional',
  },
  {
    chartId: 'competitive_reach_over_time_social',
    chartName: 'Reach Over Time - Social',
  },
  {
    chartId: 'coverage_by_journalist',
    chartName: 'Coverage by Media Contributors',
  },
  {
    chartId: 'coverage_by_source',
    chartName: 'Brand Coverage by Popular Websites',
  },
  { chartId: 'breakdown_by_media_type', chartName: 'Data Sources Breakdown' },
  {
    chartId: 'competition_companies_mentioned',
    chartName: 'Brand Mentions (Comparison)',
  },
];

export const peopleCharts = [
  { chartId: 'people_volume_analysis', chartName: 'Key Metrics Overview' },
  {
    chartId: 'people_coverage_over_time',
    chartName: 'Results Over Time ',
    traditionalChartName: 'Results Over Time - Traditional',
  },
  {
    chartId: 'people_coverage_over_time_social',
    chartName: 'Results Over Time - Social',
  },
  {
    chartId: 'people_top_journalist_by_sentiment',
    chartName: 'Media Contributors by Sentiment',
  },
  {
    chartId: 'people_top_source_by_sentiment',
    chartName: 'Data Sources by Sentiment',
  },
  { chartId: 'people_popular_topic_themes', chartName: 'Top Themes' },
  { chartId: 'people_popular_topics', chartName: 'Word Cloud' },
  {
    chartId: 'people_media_type',
    chartName: 'Coverage by Datasources Over Time',
  },
  {
    chartId: 'people_volume_comparison_analysis',
    chartName: 'Volume',
  },
  {
    chartId: 'people-reach-over-time-comparison',
    chartName: 'Reach Over Time',
  },
  {
    chartId: 'people-impact',
    chartName: 'People Impact',
  },
];

export const comparisonPeopleCharts = [
  {
    chartId: 'people_volume_comparison_analysis',
    chartName: 'Volume',
  },
  {
    chartId: 'people-reach-over-time-comparison',
    chartName: 'Reach Over Time ',
  },
  {
    chartId: 'people-impact',
    chartName: 'People Impact',
  },
];

export const industryCharts = [
  {
    chartId: 'industry_volume_analysis',
    chartName: 'Key Metrics Overview',
  },
  {
    chartId: 'industry_sentiment_analysis',
    chartName: 'Sentiment Analysis',
  },
  {
    chartId: 'industry_coverage_by_journalist',
    chartName: 'Coverage by Media Contributors',
  },
  {
    chartId: 'industry_coverage_by_source',
    chartName: 'Coverage by Datasources Over Time',
  },
  {
    chartId: 'industry_companies_mentioned',
    chartName: 'Brand Mentions',
  },
  {
    chartId: 'industry_coverage_over_time',
    chartName: 'Results Over Time ',
    traditionalChartName: 'Results Over Time - Traditional',
  },
  {
    chartId: 'industry_coverage_over_time_social',
    chartName: 'Result Over Time - Social',
  },
  // {
  //   chartId: 'industry_top_influencers',
  //   chartName: 'Top Influencers',
  // },
  {
    chartId: 'industry_coverage_by_top_publications',
    chartName: 'Coverage by Popular Websites',
  },
];

export const socialCampaignCharts = [
  { chartId: 'advocates', chartName: 'Top Advocates' },
  { chartId: 'detractors', chartName: 'Top Detractors' },
  { chartId: 'advanced_result_over_time', chartName: 'Result Over Time' },
  { chartId: 'advanced_top_themes', chartName: 'Top Themes' },
  { chartId: 'advanced_top_themes_pre', chartName: 'Top Themes' },
  { chartId: 'advanced_top_themes_during', chartName: 'Top Themes' },
  { chartId: 'advanced_top_themes_post', chartName: 'Top Themes' },
  { chartId: 'advanced_campaign_content_type', chartName: 'Content Type' },
  {
    chartId: 'advanced_campaign_engagement',
    chartName: 'Engagement by Time and Weekday',
  },
  {
    chartId: 'advanced_campaign_sentiment_over_time',
    chartName: 'Sentiment Over Time',
  },
  { chartId: 'advanced_social_overview', chartName: 'Overview' },
];

export const campaignMonitorCharts = [
  {
    chartId: 'advanced_campaign_monitor_sentiment_over_time',
    chartName: 'Sentiment',
  },
  {
    chartId: 'advanced_campaign_monitor_top_author',
    chartName: 'Top Authors',
  },
  { chartId: 'advanced_campaign_monitor_reach', chartName: 'Reach Over Time' },
  { chartId: 'advanced_campaign_monitor_themes', chartName: 'Top Themes' },
  { chartId: 'advanced_campaign_monitor_wordcloud', chartName: 'Wordcloud' },
  {
    chartId: 'advanced_campaign_monitor_outlet_breakdown',
    chartName: 'Outlet Breakdown',
  },
  {
    chartId: 'advanced_campaign_comparison_reach_by_campaign',
    chartName: 'Reach By Campaign',
  },
  {
    chartId: 'advanced_campaign_comparison_campaign_analysis',
    chartName: 'Campaign Analysis',
  },
  {
    chartId: 'advanced_campaign_monitor_vol_distribution_by_channel',
    chartName: 'Volume Distribution by Channel',
  },
  {
    chartId: 'advanced_campaign_monitor_sentiment',
    chartName: 'Sentiment',
  },
];

export const authorImpactClassName = [
  'authorimpact-no-articles-graph-download',
  'authorimpact-total-reach-graph-download',
  'authorimpact-total-ave-graph-download',
  'authorimpact-sentiment-graph-download',
];

export const dashboardWidgets = {
  brand: [...brandCharts, ...competitionCharts],
  industry: industryCharts,
  people: peopleCharts,
  brandNotComp: [...brandCharts],
  competition: [...competitionCharts],
  socialCampaign: [...socialCampaignCharts],
  campaign: [...campaignMonitorCharts],
};

export const dashboardCharts = {
  brand: brandCharts,
  competition: competitionCharts,
  industry: industryCharts,
  people: peopleCharts,
  comparisonPeopleCharts,
  socialCampaign: socialCampaignCharts,
  campaign: campaignMonitorCharts,
};

export const allDashboards = [
  ...brandCharts,
  ...competitionCharts,
  ...industryCharts,
  ...peopleCharts,
  ...comparisonPeopleCharts,
  ...socialCampaignCharts,
  ...campaignMonitorCharts,
];

// Time zone for the browser
const ianaTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const timezoneMappings = {
  // Due to bug in v8 Chrome Engine, it's still returning old one, it works fine for other engines
  // https://bugs.chromium.org/p/chromium/issues/detail?id=580195
  'Asia/Calcutta': 'Asia/Kolkata',
};
const timeZoneInfo = DateTime.local().setZone(ianaTimezone).toFormat('z');

export const timeZone = timezoneMappings[timeZoneInfo] || timeZoneInfo;

const brandClassNames = [
  'brand_volume_chart',
  'brand_sentiment_chart',
  'brand_sentiment_over_time_chart',
  'brand_coverage_over_time_chart',
  'brand_reach_over_time_chart',
  'brand_media_type_chart',
  // Competition
  'brand_competiton_sov_chart',
  'brand_competiton_coverage_over_time_chart',
  'brand_competiton_sentiment_chart',
  'brand_competiton_reach_over_time_chart',
  'brand_competiton_coverage_by_journalist_chart',
  'brand_competiton_coverage_by_source_chart',
  'brand_competiton_breakdown_by_media_type_chart',
];

const peopleClassNames = [
  'people_volume_chart',
  'people_source_by_sentiment_chart',
  'people_coverage_over_time_chart',
  'people_journalist_by_sentiment_chart',
  'people_popular_topics_chart',
  'people_media_type_chart',
];
const industryClassNames = [
  'industry_volume_chart',
  'industry_sentiment_chart',
  'industry_coverage_over_time_chart',
  'industry_coverage_by_source_chart',
  'industry_companies_mentioned_chart',
  'industry_coverage_by_journalist_chart',
  'industry_coverage_by_top_publications_chart',
];
export const standardDashboardClassNames = {
  brand: brandClassNames,
  people: peopleClassNames,
  industry: industryClassNames,
  custom: [...brandClassNames, ...peopleClassNames, ...industryClassNames],
};

// return only social filters for social related dashboards
export function filterOptionsByDashboardType(options, dashboardType) {
  try {
    return options?.map((option) => {
      if (
        option.label === 'Media Types' &&
        dashboardType === 'socialCampaign'
      ) {
        return {
          ...option,
          options: option.options.filter((item) => item.type === 'social'),
        };
      }
      return option;
    });
  } catch (error) {
    console.error('Error filtering options:', error);
    return [];
  }
}

// remove traditional filters from object
export function filterMediaTypesForSocial(searchFilter, createDashboard) {
  // Check if searchFilter exists and is not null
  if (!searchFilter || createDashboard !== 'socialCampaign') {
    return searchFilter;
  }

  const filteredSearchFilter = JSON.parse(JSON.stringify(searchFilter));

  // filter out Online and Print
  if (filteredSearchFilter.mediaTypes) {
    filteredSearchFilter.mediaTypes = filteredSearchFilter.mediaTypes.filter(
      (mediaType) =>
        mediaType.value.toLowerCase() !== 'online' &&
        mediaType.value.toLowerCase() !== 'print'
    );
  }

  return filteredSearchFilter;
}

// returns true if social channels are present
export function validateMediaTypesForSocialDashboard(searchFilter) {
  // If mediaTypes doesn't exist, return true (assume all media is selected)
  if (!searchFilter?.mediaTypes || searchFilter.mediaTypes.length === 0) {
    return true;
  }

  // Check if mediaTypes contains only Online or Print
  const onlyTraditionalMedia = searchFilter.mediaTypes.every(
    (mediaType) =>
      mediaType.value.toLowerCase() === 'online' ||
      mediaType.value.toLowerCase() === 'print'
  );

  // If only traditional media exists, return false
  if (onlyTraditionalMedia) {
    return false;
  }

  // If any other media type exists, return true
  return searchFilter.mediaTypes.some(
    (mediaType) =>
      mediaType.value.toLowerCase() !== 'online' &&
      mediaType.value.toLowerCase() !== 'print'
  );
}

// get chart name for drilldown and change it if necessary to pass on to backend
export const authorSummaryKey = (articleType, activeTab) => {
  if (articleType?.widget === 'Author Impact') {
    const authorKey = articleType?.otherInfo?.d?.key;
    const basedName = articleType?.otherInfo?.uniqueId;

    if (authorKey === 'no_of_articles') {
      return `${basedName}_${authorKey}`;
    } else if (authorKey === 'total_reach') {
      return `${basedName}_reach`;
    } else if (authorKey === 'total_ave') {
      return `${basedName}_ave`;
    } else {
      return `${basedName}_sentiment`;
    }
  }

  // Map widgets coming from articleType?.otherInfo?.uniqueId || articleType.rawData.uniqueId
  const keyMap = {
    people_coverage_over_time: 'people_results_over_time_traditional',
    people_coverage_over_time_social: 'people_results_over_time_social',
    people_popular_topic_themes: 'people_popular_topics',
    people_volume_comparison_analysis:
      'people_top_media_contributors_comparison',
    'people-reach-over-time-comparison': 'people_reach_over_time_comparison',
    'people-impact': 'people_campaign_monitor_comparison',
  };

  // Map widgets coming from articleType?.widget
  const AdvancedWidgetMap = {
    advanced_campaign_monitor_reach: 'campaign_reach_over_time',
    advanced_campaign_monitor_sentiment_over_time: 'campaign_top_sentiment',
    advanced_campaign_monitor_top_author: 'campaign_top_authors',
    advanced_campaign_monitor_outlet_breakdown: 'campaign_outlet_breakdown',
    advanced_campaign_monitor_themes: 'campaign_top_theme',
    advanced_campaign_comparison_reach_by_campaign:
      'campaign_comparison_reach_over_time',
    advanced_campaign_comparison_campaign_analysis:
      'campaign_comparison_campaign_analysis',
    advanced_campaign_monitor_sentiment: 'campaign_comparison_sentiment',
    advanced_result_over_time: 'sc_result_over_time',
    advanced_top_themes: 'sc_topthemes',
    advanced_top_themes_pre: 'sc_topthemes',
    advanced_top_themes_during: 'sc_topthemes',
    advanced_top_themes_post: 'sc_topthemes',
    advanced_campaign_sentiment_over_time: 'sc_sentiment_over_time',
    advanced_campaign_engagement: 'sc_engagement_by_weeks',
    advanced_campaign_content_type: 'sc_content_type',
    detractors: 'sc_detractors',
    advocates: 'sc_advocates',
  };

  // Handle special case for primpact
  if (articleType?.otherInfo?.uniqueId === 'primpact' && activeTab === 999) {
    return 'comparison_primpact';
  }

  // Check for outlet comparison in campaign monitor
  if (
    articleType?.widget?.startsWith(
      'advanced_campaign_monitor_vol_distribution_by_channel'
    )
  ) {
    return 'campaign_comparison_outlet_breakdown';
  }

  // Check in rawData uniqueId
  if (articleType?.rawData?.uniqueId) {
    const key = keyMap[articleType.rawData.uniqueId];
    if (key) return key;
  }

  // Check in otherInfo uniqueId
  if (articleType?.otherInfo?.uniqueId) {
    const key = keyMap[articleType.otherInfo.uniqueId];
    if (key) return key;
  }

  // Check widget type
  if (articleType?.widget) {
    const key = AdvancedWidgetMap[articleType.widget];
    if (key) return key;
  }

  // check widget from component
  if (articleType?.otherInfo?.widget?.component) {
    const key = AdvancedWidgetMap[articleType?.otherInfo?.widget?.component];
    if (key) return key;
  }
  // Default fallback
  return articleType?.otherInfo?.uniqueId;
};

import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  GraphNoDataText,
  CommentaryLabel,
  CommentarySection,
  AiSection,
  // BottomDeswpr,
  // BottomInfowpr,
  // GraphTypeBtn,
  // GraphTypeBtnWrapper,
  IconBox,
  Iconwpr,
  LegendSection,
  LegendSectionV2,
  LineShow,
  // Line,
  // GraphTypeBtn,
  // GraphTypeBtnWrapper,
  SlotBody,
  // SlotBodyTabBody,
  // SlotBodyTabWrp,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotOverviewWrapper,
  // SlotHeaderRight,
  // SlotSubTitle,
  SlotTitle,
  SlotTypeTitle,
  RefreshIconWrp,
  SlotSubTitle,
  TopThemeToggleWrp,
  StyledMarkdown,
  LegendBox,
  LegendLabel,
  // TopInfowpr,
} from '../index.sc';
// import Loader from '../../loader';
import PortalTooltip from '../../portal-tooltip';
import { graphTypes, widgetMapping } from '../../../constants/widgets';
import Edit2 from '../../../assets/icons/Edit2';
import { VerticleDots } from '../../../assets/icons/VerticleDots';
import GraphLegend from '../../graph-legend';
import GraphLegendOutlet from '../../graph-legend-outlet';
import GraphLegendV2 from '../../graph-legend-v2';
import GraphTooltip from '../../graph-tooltip';
import DashboardPopup from '../../dasboard-popup';
import EditGraphPopup from '../../edit-graph-popup';
import SlotOverview from './SlotOverview';
import CircularLoading from '../../../assets/icons/loading/circularLoading';
import { formatNumber, getSelectedTypes, trimmedData } from '../../../utils';
import { LinearLine } from '../../../graphs';
import SimpleReusableDropDown from '../../simple-dropdown';
import DownloadIcon from '../../../assets/icons/DownloadIcon';
import { useParams } from 'react-router-dom';
import RefreshIcon from '../../../assets/icons/RefreshIcon';
import Tooltip from '../../icon-tooltip';
import HelpIcon from '../../../assets/icons/HelpIcon';
import ChartToolTip from '../../chart-tool-tip';
import ReachTooltip from '../../tooltip';
import { theme } from '../../../constants/theme';
import {
  coolGrayColorGradients,
  magentaColorGradients,
  purpleColorGradients,
} from '../../../constants/graph-colors';
import ToggleSwitch from '../../toggle-switch';
import { useSelector } from 'react-redux';
import { outletBreakDown } from '../../../graphs/utils/graphConst';
import { reader } from '../../../constants';
import ThreeDotsLoader from '../../three-dots-loader';
import { NewsGraphWrp } from '../../../pages/news-letter/newsletter-add-section/add-item-component/graph-item/index.sc';

const gridXTicksCount = 6;
// check media type filter
function determineMediaType(data) {
  // Check if the data object is empty
  if (Object.keys(data).length === 0) {
    return ['all'];
  }

  // Check if the mediaTypes array exists and is not empty
  if (data.mediaTypes && data.mediaTypes.length > 0) {
    const mediaLabels = data.mediaTypes.map((media) => media.label);
    const mediaArray = [];

    // Check for specific conditions and add them to the array
    if (mediaLabels.includes('Print')) {
      mediaArray.push('print');
    }
    if (mediaLabels.includes('Online')) {
      mediaArray.push('online');
    }
    if (mediaLabels.includes('Forums')) {
      mediaArray.push('forums');
    }
    if (mediaLabels.includes('Blogs')) {
      mediaArray.push('blogs');
    }
    if (mediaLabels.includes('Reviews')) {
      mediaArray.push('reviews');
    }

    if (mediaLabels?.includes('X (Twitter)')) {
      mediaArray.push('twitter');
    }
    if (mediaLabels?.includes('Reddit')) {
      mediaArray.push('reddit');
    }

    // If all media types are present, return 'all'
    if (mediaArray.length === 7) {
      return ['all'];
    }

    return mediaArray;
  }

  // Default case if none of the above conditions are met
  return ['all'];
}

const generateGraphComponent = (
  widget,
  defaultConfig,
  type,
  dashboardType,
  canvas,
  resetSelection = false,
  rerender,
  showAllSubCircles = false,
  themeComponent = false,
  filters = {},
  summaryComponentHeight = 0
) => {
  const widgetDetails = {
    dashboardType,
    type,
    component: widget.component,
  };
  const { bentoView } =
    (widgetMapping[dashboardType] &&
      widgetMapping[dashboardType][widget.component]) ||
    {};
  const GraphComponent =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType]?.component
      : bentoView[type]?.component;

  const maxData =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType]?.maxData
      : bentoView[type]?.maxData;

  const dataCount = widget?.data?.data?.length;
  const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

  const canvasConfig = { gridXTicks: xTicksCount };

  let finalConfig = {
    ...(type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType]?.config
      : bentoView[type]?.config),
    ...defaultConfig,
    ...widgetDetails,
    ...(canvas && canvasConfig),
    ...(type === 'dashboard' ? {} : { handleOnClick: undefined }),
    summaryContainerHeight: summaryComponentHeight,
  };

  if (
    // finalConfig?.component === 'sentiment_over_time' ||
    finalConfig?.component === 'coverage_over_time' ||
    finalConfig?.component === 'reach_over_time' ||
    // finalConfig?.component === 'media_type' ||
    finalConfig?.component === 'coverage_over_time' ||
    finalConfig?.component === 'reach_over_time' ||
    finalConfig?.component === 'industry_coverage_by_source' ||
    finalConfig?.component === 'industry_coverage_over_time' ||
    finalConfig?.component === 'people_coverage_over_time' ||
    finalConfig?.component === 'people_media_type'
  ) {
    finalConfig = {
      ...finalConfig,
      singleLineWrp: false,
      showAllLabels: true,
    };
  }
  if (finalConfig.component === 'result_over_time') {
    finalConfig = {
      ...finalConfig,
      fromNewsletter: true,
    };
  }
  if (
    dashboardType === 'overview' &&
    (widget.graphType === 'pie' || widget.graphType === 'donut')
  ) {
    finalConfig = {
      ...finalConfig,
      fromExecutiveDashboard: true,
    };
  }
  if (
    finalConfig?.component === 'top_source' ||
    finalConfig?.component === 'industry_coverage_by_top_publications' ||
    finalConfig?.component === 'people_top_source_by_sentiment'
  ) {
    finalConfig = {
      ...finalConfig,
      capitalizeLabel: true,
    };
  }
  if (
    finalConfig?.component === 'top_author' ||
    finalConfig?.component === 'industry_coverage_by_journalist'
  ) {
    finalConfig = {
      ...finalConfig,
      colorGradientDark: theme?.light?.graphColors?.blue50,
      colorGradientLight: theme?.light?.graphColors?.cyan30,
      dropShadowColor: theme?.light?.graphColors?.cyan40,
      authorDataLength: widget?.data?.data?.length * 10,
      graphTopPadding: 0,
    };
  }

  if (finalConfig?.component === 'people_top_author') {
    finalConfig = {
      ...finalConfig,
      graphVersion: 'v2',
      authorDataLength: widget?.data?.data?.length * 10,
      graphTopPadding: 0,
    };
  }

  if (finalConfig?.component === 'top_themes') {
    finalConfig = {
      ...finalConfig,
      enableSequenceBubble: true,
      showAllSubCircles,
      maxBubbleSize: themeComponent ? 80 : 120,
    };
  }
  if (
    finalConfig?.component === 'article_sentiment' ||
    finalConfig?.component === 'people_top_source_by_sentiment' ||
    finalConfig?.component === 'people_top_journalist_by_sentiment'
  ) {
    finalConfig = {
      ...finalConfig,
      greenGradientLight: theme?.light?.graphColors?.green40,
      greenGradientDark: theme?.light?.graphColors?.green50,
      redGradientLight: theme?.light?.graphColors?.red40,
      redGradientDark: theme?.light?.graphColors?.red50,
      grayGradientLight: theme?.light?.graphColors?.coolGray30,
      grayGradientDark: theme?.light?.graphColors?.coolGray40,
      greenDropShadowColor: theme?.light?.graphColors?.green50,
      redDropShadowColor: theme?.light?.graphColors?.red50,
      grayDropShadowColor: '#A8ADBD',
      barHeight: finalConfig?.component === 'article_sentiment' ? 24 : 16,
      articleSentiment: true,
    };
  }

  if (
    finalConfig?.component === 'competitive_reach_over_time' ||
    finalConfig?.component === 'competitive_coverage_over_time' ||
    finalConfig?.component === 'coverage_over_time' ||
    finalConfig?.component === 'reach_over_time' ||
    finalConfig?.component === 'industry_coverage_over_time' ||
    finalConfig?.component === 'people_coverage_over_time'
  ) {
    finalConfig = {
      ...finalConfig,
      overTimeChart: true,
      enableCurve: false,
      enableTooltipTrendPointer: true,
      enableGradient: !(
        finalConfig?.component === 'competitive_reach_over_time' ||
        finalConfig?.component === 'competitive_coverage_over_time'
      ),
    };
  }

  if (finalConfig?.component === 'breakdown_by_media_type') {
    finalConfig = {
      ...finalConfig,
      breakDownByMedia: true,
      enableGridYLine: false,
      columnWidth: 24,
      columnGroupPadding: 8,
      mediaType: determineMediaType(filters),
    };
  }

  if (finalConfig.component === 'outlet_breakdown') {
    finalConfig = {
      ...finalConfig,
      mediaType: determineMediaType(filters),
      isStackedOutlet: true,
      greenGradientLight: theme?.light?.graphColors?.green40,
      greenGradientDark: theme?.light?.graphColors?.green50,
      redGradientLight: theme?.light?.graphColors?.red40,
      redGradientDark: theme?.light?.graphColors?.red50,
      grayGradientLight: theme?.light?.graphColors?.coolGray30,
      grayGradientDark: theme?.light?.graphColors?.coolGray40,
      greenDropShadowColor: theme?.light?.graphColors?.green50,
      redDropShadowColor: theme?.light?.graphColors?.red50,
      articleSentiment: true,
      barHeight: 50,
      hideXAxis: true,
    };
  }

  if (
    finalConfig?.component === 'media_type' ||
    finalConfig?.component === 'industry_coverage_by_source' ||
    finalConfig?.component === 'people_media_type'
  ) {
    finalConfig = {
      ...finalConfig,
      singleLineWrp: false,
      showAllLabels: true,
      // columnWidth:
      //   finalConfig?.component === 'industry_coverage_by_source' ||
      //   finalConfig?.component === 'people_media_type'
      //     ? 16
      //     : 20,
      columnWidth: 20,
      columnGroupPadding: 4,
      mediaTypeChart: true,
      mediaType: determineMediaType(filters),
      enableCurve: false,
      enableTooltipTrendPointer: true,
    };
  }

  if (finalConfig?.component === 'sentiment_over_time') {
    finalConfig = {
      ...finalConfig,
      singleLineWrp: false,
      showAllLabels: true,
      sentimentOverTime: true,
    };
  }

  if (finalConfig?.component === 'people_top_source_by_sentiment') {
    finalConfig = {
      ...finalConfig,
      greenGradientLight: theme?.light?.graphColors?.green40,
      greenGradientDark: theme?.light?.graphColors?.green50,
      redGradientLight: theme?.light?.graphColors?.red40,
      redGradientDark: theme?.light?.graphColors?.red50,
      grayGradientLight: theme?.light?.graphColors?.coolGray30,
      grayGradientDark: theme?.light?.graphColors?.coolGray40,
      greenDropShadowColor: theme?.light?.graphColors?.green50,
      redDropShadowColor: theme?.light?.graphColors?.red50,
      grayDropShadowColor: '#A8ADBD',
      articleSentiment: true,
    };
  }

  return widget?.shouldShowGraph ? (
    <GraphComponent
      data={trimmedData(widget.data, maxData)}
      config={finalConfig}
      resetSelection={resetSelection}
      rerender={rerender}
      handleOnClick={defaultConfig?.handleOnClick}
    />
  ) : (
    <GraphNoDataText>No Data</GraphNoDataText>
  );
};

const SlotDetails = ({
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  legend = false,
  commentary = false,
  resetSelection = false,
  handleOnClick = () => {},
  handleUpdatedChart = () => {},
  handleShowDownloadPopUp = () => {},
  overRideSlot,
  editOption = false,
  actionOption = false,
  downloadFunction,
  setSelectedComponent,
  selectedComponent,
  graphDownloading,
  widgetClassName,
  searchId,
  chartNames,
  handleGraphTitleUpdate = () => {},
  editChart,
  isSavePopup,
  chartRefresh = () => {},
  helperText = '',
  customGridDashboard = '',
  onDownloadChartData,
  themeComponent = false, // comes from newsletter
  filters = {},
  insight = {},
  insightLoading = false,
  footerMarginTop = '',
  donutWidth = false,
  newsSentimentChart = false,
  alignBreakDownLegends = false,
  widgetClassNameTwo = '',
  accessTypeViewUser = false,
}) => {
  const { dashboardId } = useParams();
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [tooltipEvent, setTooltipEvent] = useState({});
  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const [editChart2, setEditChart2] = useState({
    chartName: editChart?.chartName || widget?.title,
    chartType: widget?.graphType || '',
  });
  const [rerender, setRerender] = useState(false);
  const [toggleChartTip, setToggleChartTip] = useState(false);
  const [showAllSubCircles, setShowAllSubCircles] = useState(
    themeComponent ?? false
  );

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });
  const role = useSelector((state) => state?.user?.role);

  const getSelector = (state) => {
    if (dashboardType === 'primpact') {
      return state.prImpact;
    } else if (dashboardType === 'people') {
      return state.people;
    } else if (dashboardType === 'campaign') {
      return state.campaignMonitor;
    } else {
      return state.advancedDashboardTab;
    }
  };
  const { activeTab } = useSelector(getSelector);

  const containerRef = useRef(null);
  const downloadRef = useRef(null);

  const titleRef = useRef(null);
  const summaryHeightRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });

  const handleClickOutside = (event) => {
    if (downloadRef.current && !downloadRef.current.contains(event.target)) {
      setOpenActionDropDown(false);
    }
  };
  const graphData = widget;

  const customDashboard = 'custom';

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setRerender((old) => !old);
  }, [widget]);

  // const handleDownloadSingleGraph = () => {
  //   downloadFunction();
  // };
  const actionDropDownOptions = [
    {
      label: 'Download Image',
      type: 'Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    }, // Replace <Icon1 /> with your actual icon component
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
  ];

  // const [activeBtn, setActiveBtn] = useState('trendline');
  // console.log(widgetMapping[dashboardType][widget.component], 'dashboardType');

  // console.log(widgetMapping, widget.component, 'this is in slot details');
  const tooltipEnabled = true;

  const getLabelColor = (data) => {
    if (
      widgetClassName === 'geographical-graph-download' &&
      parseInt(data?.properties?.value) > 0
    ) {
      return purpleColorGradients.purple60;
    } else if (
      widgetClassName === 'geographical-graph-download' &&
      parseInt(data?.properties?.value) > 0
    ) {
      return coolGrayColorGradients.coolGray60;
    } else if (widgetClassName === 'outletmedia-graph-download') {
      return outletBreakDown[
        data?.parentData?.parentData?.value?.findIndex(
          (value) => value?.label === data?.parentData?.label
        )
        // ? 0
        // : data?.parentData?.label === 'Traditional' ||
        //   data?.parentData?.label === 'Online' ||
        //   data?.parentData?.label === 'x (twitter)' ||
        //   data?.parentData?.label === 'Reddit'
        // ? 0
        // : data?.parentData?.label === 'Print'
        // ? 1
        // : 5
      ];
    }
    return data?.labelColor || data?.rawData?.labelColor;
  };

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setTooltipEvent({
        event,
        d,
        i,
      });
      const tData = d.data || d;
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });

      setTooltipData({
        data: tData,
        rawData: d?.rawData,
        labelColor:
          getLabelColor(tData) || tData?.parentData?.parentData?.color,
      });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setTooltipEvent({
        event,
        d,
        i,
      });
      const tData = d.data || d;
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setTooltipEvent({});
      setToolTipPos({ left: 0, top: 0 });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick,
  };

  if (dashboardType === 'grid-dashboard') {
    delete defaultConfig.handleOnClick;
  }

  useEffect(() => {
    const handleScroll = () => {
      if (enableTooltip) {
        setEnableTooltip(false);
      }
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [enableTooltip]);

  // const handleTabBtnClick = (e, tab) => {
  //   e.stopPropagation();
  //   setActiveBtn(tab);
  // };

  // const reducedHeight =
  //   widget?.information && widget?.bottomDescription
  //     ? 3
  //     : widget?.information
  //     ? 2
  //     : widget?.bottomDescription
  //     ? 1
  //     : 0;

  const traditional = {
    type: 'Traditional',
    color: purpleColorGradients.purple60,
    dataKey: 'totalTraditionCount',
  };

  const social = {
    type: 'Social',
    color: magentaColorGradients.magenta50,
    dataKey: 'totalSocialCount',
  };

  const mediaMappings = {
    social: [
      {
        ...social,
        type: 'Results',
      },
    ],
    traditional: [
      {
        ...traditional,
        type: 'Results',
      },
    ],
    all: [social, traditional],
  };

  const summary = graphData?.data?.summary;
  let legendData = [];
  let selectedMedia = '';
  const twoD = graphData?.data?.labels?.length > 1;
  if (graphData?.data?.legends?.length > 0) {
    legendData = [...graphData.data.legends];
  } else {
    if (twoD) {
      if (
        graphData?.component === 'media_type' ||
        widget.component === 'industry_coverage_by_source' ||
        widget.component === 'people_media_type'
      ) {
        const mediaTypeName = graphData?.data?.summary?.mediaType;
        const filteredLegends = graphData?.data?.labels.filter((item) =>
          mediaTypeName === null ? item : mediaTypeName?.includes(item.label)
        );
        legendData = filteredLegends.map((ele) => ({
          label: ele?.label,
          value: ele?.label?.replaceAll(' ', '').toLowerCase(),
          color: ele?.color,
        }));
      } else {
        legendData = graphData?.data?.labels.map((ele) => ({
          label: ele?.label,
          value: ele?.label?.replaceAll(' ', '').toLowerCase(),
          color: ele?.color,
        }));
      }
    } else {
      if (graphData?.component === 'result_over_time') {
        selectedMedia = getSelectedTypes(graphData?.data?.summary);
      } else {
        legendData = graphData?.data?.legends?.map((ele) => ({
          label: ele?.label,
          value: ele?.value,
          color: ele?.color,
        }));
      }
    }
  }

  const handleGraphEditClick = (e) => {
    // e.preventDefault();
    setPopupIsOpen(!popupIsOpen);
  };

  const handleUpdateGraph = async (e, data, updatedChartData) => {
    e.stopPropagation();
    // console.log(data, 'edit data');
    handleUpdatedChart(data);
    const chartData = chartNames?.data?.find(
      (chart) => chart?.default_name === graphData?.title
    );
    handleGraphTitleUpdate(
      updatedChartData,
      data,
      chartData,
      widgetClassName,
      data?.chartType
    );
    handleGraphEditClick(e);
    // if (searchId !== 'custom-search') {
    //   onUpdateChartNames(updatedChartData);
    // }
  };

  const handleEditChart = (data) => {
    setEditChart2(data);
  };

  const handleOptionIcon = (e, componentName) => {
    // e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown((prev) =>
      prev !== componentName ? componentName : false
    );
  };

  const showLineBar = () => {
    const { bentoView } =
      (widgetMapping[dashboardType] &&
        widgetMapping[dashboardType][graphData.component]) ||
      {};

    const GraphComponent =
      type === 'dashboard' || type === 'l2'
        ? graphTypes[graphData.graphType]?.component
        : bentoView[type]?.component;

    return GraphComponent === LinearLine;
  };

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 24, // Added width of icon and padding
      });
    }
  }, [editChart?.chartName, editChart2?.chartName]);

  const getSlotBodyStyles = () => {
    if (
      widgetClassName === 'geographical-graph-download' ||
      widgetClassName === 'volume_analysis' ||
      widgetClassName === 'toptheme-graph-download'
    ) {
      return { padding: '1rem' };
    } else if (graphData?.component === 'top_author') {
      return { padding: '0 1.25rem 2.5rem 1.25rem' };
    } else if (widgetClassName === 'wordcloud-graph-download') {
      return {
        paddingRight: '1rem',
      };
    }
    return {};
  };

  useEffect(() => {
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
  }, []);

  return (
    <SlotDetailsMainWrp
      type={type}
      ref={containerRef}
      className={widgetClassName + ' ' + widgetClassNameTwo}
    >
      {/* <button onClick={() => setResetSelection(true)}>reset</button> */}

      <SlotDetailsWrp className="sentiment-graph-download">
        <SlotHeader className="hide-download">
          <SlotHeaderLeft style={{ marginLeft: '1.5rem' }}>
            <SlotTitle ref={titleRef}>
              {dashboardId ? editChart?.chartName : editChart2?.chartName}
              {(dashboardType === 'custom' ||
                customGridDashboard === 'custom') && (
                <SlotTypeTitle
                  type={widgetMapping[customDashboard][widget.component]?.type}
                >
                  {widgetMapping[customDashboard][
                    widget.component
                  ]?.type?.toUpperCase()}
                </SlotTypeTitle>
              )}
            </SlotTitle>
            {actionOption &&
            (widgetClassName === 'competitive_reach_over_time' ||
              widgetClassName === 'reach_over_time') ? (
              <ReachTooltip
                content="Reach estimates the potential viewership of any particular article based on the number of visitors to the specific source on both desktop and mobile"
                direction="right"
              >
                {activeTab !== 999 && <HelpIcon />}
              </ReachTooltip>
            ) : (
              actionOption && (
                <>
                  <span
                    style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                    onMouseEnter={() => setToggleChartTip(true)}
                    onMouseLeave={() => setToggleChartTip(false)}
                  >
                    {activeTab !== 999 && <HelpIcon />}
                  </span>
                  {/* <SlotSubTitle>{widget.subTitle}</SlotSubTitle> */}
                  {toggleChartTip && (
                    <ChartToolTip
                      text={helperText}
                      dashboardType={dashboardType === 'custom'}
                      componentLeft={iconPosition?.left}
                      componentTop={8.5}
                    />
                  )}
                </>
              )
            )}
          </SlotHeaderLeft>

          <IconBox
            type={type}
            isSavePopup={isSavePopup}
            style={{ marginRight: '1.5rem' }}
          >
            {/* <Iconwpr width={'1.5rem'} height={'1.5rem'} onClick={handleClick}>
          <ExpandIcon />
        </Iconwpr> */}
            {/* {overRideSlot && (
          <Iconwpr onClick={handleShowDownloadPopUp}>
            <ComponentIcon color="gray" />
          </Iconwpr>
        )} */}
            {widget?.component === 'top_themes' &&
              actionOption &&
              dashboardType === 'overview' && (
                <Tooltip content="refresh">
                  <RefreshIconWrp onClick={chartRefresh}>
                    <RefreshIcon />
                  </RefreshIconWrp>
                </Tooltip>
              )}

            {(widget?.shouldShowGraph &&
              editOption &&
              role !== reader &&
              !accessTypeViewUser && (
                <Iconwpr
                  width={'1.5rem'}
                  height={'1.5rem'}
                  onClick={handleGraphEditClick}
                  className="hide-downloading"
                >
                  <Edit2 />
                </Iconwpr>
              )) || <></>}

            {(widget?.shouldShowGraph && actionOption && role !== reader && (
              <>
                <Iconwpr
                  width={'1.5rem'}
                  height={'1.5rem'}
                  onClick={(e) => {
                    handleOptionIcon(e, graphData.component);
                  }}
                  ref={downloadRef}
                  className="hide-downloading"
                >
                  <VerticleDots
                    color={
                      openActionDropdown === selectedComponent
                        ? '#675ef2'
                        : '#5C5E60'
                    }
                  />
                  <SimpleReusableDropDown
                    isOpen={openActionDropdown === selectedComponent}
                    options={actionDropDownOptions}
                    graphDownloading={graphDownloading}
                    setIsOpen={setOpenActionDropDown}
                  />
                </Iconwpr>
              </>
            )) || <></>}
          </IconBox>

          {/* {widget.enableTabs && (
            <SlotHeaderRight>
              <GraphTypeBtnWrapper>
                <GraphTypeBtn
                  className={activeBtn === 'trendline' ? 'active' : ''}
                  onClick={(e) => handleTabBtnClick(e, 'trendline')}
                >
                  Trendline
                </GraphTypeBtn>
                <GraphTypeBtn
                  className={activeBtn === 'bar' ? 'active' : ''}
                  onClick={(e) => handleTabBtnClick(e, 'bar')}
                >
                  Bar
                </GraphTypeBtn>
              </GraphTypeBtnWrapper>
            </SlotHeaderRight>
          )} */}
        </SlotHeader>
        {type === 'dashboard' && (
          <SlotOverviewWrapper
            style={{ marginBottom: '10px', marginLeft: '1.5rem' }}
            className="hide-download"
          >
            {widget?.graphType !== 'uber_stats_volume' && (
              <SlotOverview summary={graphData?.data?.summary} />
            )}
          </SlotOverviewWrapper>
        )}
        <SlotBody
          type={type}
          className={`${type === 'dashboard' && legend ? 'legend' : ''} ${
            type === 'dashboard' && commentary ? 'commentary' : ''
          } ${widgetClassName + '-body'}`}
          style={getSlotBodyStyles()}
        >
          {legend &&
            legendData?.length <= 6 &&
            (graphData?.graphType === 'pie' ||
              graphData?.graphType === 'donut') &&
            graphData?.shouldShowGraph && (
              <LegendSectionV2
                length={legendData?.length}
                type={type}
                newsSentimentChart={newsSentimentChart}
                dashboardType={dashboardType}
              >
                <GraphLegendV2
                  dashboardType={dashboardType}
                  legendData={graphData?.data?.data}
                  mediaType={determineMediaType(filters)}
                />
              </LegendSectionV2>
            )}
          {loader ? (
            <CircularLoading
              size="0.25rem"
              width="1.875rem"
              height="1.875rem"
            />
          ) : (
            <NewsGraphWrp width={donutWidth}>
              {generateGraphComponent(
                graphData,
                defaultConfig,
                type,
                dashboardType,
                canvas,
                resetSelection,
                rerender,
                showAllSubCircles,
                themeComponent,
                filters,
                summaryHeightRef?.current?.clientHeight
              )}
            </NewsGraphWrp>
          )}
          {/* {showLineBar() && Object.keys(tooltipEvent).length > 0 && (
            <LineShow xAxis={tooltipEvent?.event?.offsetX + 2}></LineShow>
          )} */}
          {tooltipEnabled && enableTooltip && (
            <PortalTooltip
              isOpen={true}
              pos={toolTipPos}
              align={toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'}
              vAlign={
                toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
              }
              isAlign={true}
              boxShadow="0px 8px 20px 0px rgba(0, 0, 0, 0.12)"
            >
              <GraphTooltip
                tooltipData={tooltipData}
                type="two-d"
                widget={graphData}
              />
            </PortalTooltip>
          )}
        </SlotBody>
        {widget?.component === 'top_themes' && !themeComponent && (
          <TopThemeToggleWrp className="hide-download">
            <SlotSubTitle>Show Secondary Themes</SlotSubTitle>
            <ToggleSwitch
              accentColor={theme[selectedTheme].primary}
              checked={showAllSubCircles}
              onChange={() => setShowAllSubCircles(!showAllSubCircles)}
              tempWidth="2.5rem"
              tempHeight="1.25rem"
              toggleWidth="1rem"
              toggleHeight="1rem"
            />
          </TopThemeToggleWrp>
        )}
        {legend &&
          widgetClassName !== 'people_popular_topics' &&
          widget?.component !== 'top_themes' &&
          widget?.component !== 'industry_coverage_by_top_publications' && (
            <SlotFooter
              style={{
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
                marginTop: footerMarginTop,
              }}
            >
              {legend &&
                legendData?.length <= 8 &&
                graphData?.graphType !== 'pie' &&
                graphData?.graphType !== 'donut' &&
                graphData?.component !== 'outlet_breakdown' && (
                  <LegendSection>
                    <GraphLegend legendData={legendData} />
                  </LegendSection>
                )}

              {legend && graphData?.component === 'outlet_breakdown' && (
                <LegendSection
                  justify="flex-start"
                  alignItem={alignBreakDownLegends}
                  className="hide-download"
                >
                  <GraphLegendOutlet legendData={graphData?.data?.data} />
                </LegendSection>
              )}
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                {selectedMedia === 'all' &&
                  mediaMappings.all.map((item) => {
                    return (
                      <React.Fragment key={item.type}>
                        <LegendBox bgColor={item.color} />
                        &nbsp;
                        <LegendLabel>
                          {item.type} :{' '}
                          {summary?.[item.dataKey]
                            ? formatNumber(summary[item.dataKey])
                            : 0}
                        </LegendLabel>
                        <LegendLabel> &nbsp; | &nbsp;</LegendLabel>
                      </React.Fragment>
                    );
                  })}
                {selectedMedia === 'traditional' && (
                  <p
                    style={{
                      color: coolGrayColorGradients.coolGray60,
                      fontSize: '11px',
                      margin: 0,
                      padding: 0,
                      fontWeight: 500,
                    }}
                  >
                    Trendline for traditional channels
                  </p>
                )}
                {selectedMedia === 'social' && (
                  <p
                    style={{
                      color: coolGrayColorGradients.coolGray60,
                      fontSize: '11px',
                      margin: 0,
                      padding: 0,
                      fontWeight: 500,
                    }}
                  >
                    Trendline for social channels
                  </p>
                )}
              </div>
            </SlotFooter>
          )}
        {dashboardType === 'overview' && (
          <div>
            <CommentarySection className="hide-download" ref={summaryHeightRef}>
              {!insightLoading ? (
                <StyledMarkdown>{insight?.data?.summary}</StyledMarkdown>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    minHeight: '3rem',
                    alignItems: 'center',
                  }}
                >
                  <ThreeDotsLoader />
                </div>
              )}
            </CommentarySection>
          </div>
        )}
      </SlotDetailsWrp>
      <DashboardPopup
        open={popupIsOpen}
        toggler={handleGraphEditClick}
        width={'40%'}
        popContent={
          <EditGraphPopup
            popupIsOpen={popupIsOpen}
            handleEditClick={handleUpdateGraph}
            handleClose={handleGraphEditClick}
            widgetTitle={editChart?.chartName}
            handleEdit={handleEditChart}
            widget={graphData}
          />
        }
      />
    </SlotDetailsMainWrp>
    // <Line />
    // <BottomInfowpr>
    //     {widget?.bottomInformation ||
    //       'Insights : Media engagement of “covid vaccine” increased by 22% in jan 2021 “covid vaccine” increased by 22% in jan 2021'}
    //   </BottomInfowpr>
  );
};

SlotDetails.defaultProps = {
  type: 'dashboard',
};

SlotDetails.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  legend: Proptypes.bool,
  commentary: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  handleShowDownloadPopUp: Proptypes.func,
  overRideSlot: Proptypes.bool,
  editOption: Proptypes.bool,
  actionOption: Proptypes.bool,
  downloadFunction: Proptypes.func,
  setSelectedComponent: Proptypes.func,
  graphDownloading: Proptypes.bool,
  widgetClassName: Proptypes.string,
  searchId: Proptypes.string,
  chartNames: Proptypes.object || undefined,
  handleGraphTitleUpdate: Proptypes.func,
  editChart: Proptypes.object,
  isSavePopup: Proptypes.bool,
  chartRefresh: Proptypes.func,
  helperText: Proptypes.string,
  customGridDashboard: Proptypes.string,
  onDownloadChartData: Proptypes.func,
  themeComponent: Proptypes.bool,
  filters: Proptypes.object,
  insight: Proptypes.object,
  insightLoading: Proptypes.bool,
  footerMarginTop: Proptypes.string,
  donutWidth: Proptypes.bool,
  newsSentimentChart: Proptypes.bool,
  alignBreakDownLegends: Proptypes.bool,
  widgetClassNameTwo: Proptypes.string,
  selectedComponent: Proptypes.string,
  accessTypeViewUser: Proptypes.bool,
};

export default SlotDetails;
